import { Injectable, OnDestroy } from '@angular/core';

import { BehaviorSubject, fromEvent, Subject, takeUntil } from 'rxjs';

import { SignoutService } from '@services/signout.service';

@Injectable({
  providedIn: 'root',
})
export class ActiveService implements OnDestroy {
  public isActiveSubject$ = new BehaviorSubject<boolean>(true);
  public isConnected = true;
  private isVisible = true;
  public asleepMinutes = 0;

  private destroy$ = new Subject<void>();

  constructor(
    private signout: SignoutService,
  ) {
    this.resumeIfPaused();

    // Set default online state and then listen for changes
    navigator.onLine ? this.changesIfOnline() : this.changesIfOffline();

    fromEvent(window, 'online')
      .pipe(takeUntil(this.destroy$), takeUntil(this.signout.signoutSubject$))
      .subscribe(() => this.changesIfOnline());

    fromEvent(window, 'offline')
      .pipe(takeUntil(this.destroy$), takeUntil(this.signout.signoutSubject$))
      .subscribe(() => this.changesIfOffline());

    fromEvent(document, 'visibilitychange')
      .pipe(takeUntil(this.destroy$), takeUntil(this.signout.signoutSubject$))
      .subscribe(() => {
        const isVisible = document.visibilityState === 'visible';
        if (this.isVisible !== isVisible) {
          this.setIsVisible(isVisible);
        }
      });

    fromEvent(window, 'focus')
      .pipe(takeUntil(this.destroy$), takeUntil(this.signout.signoutSubject$))
      .subscribe(() => {
        if (!this.isVisible) {
          this.setIsVisible(true);
        }
      });

    fromEvent(window, 'resume')
      .pipe(takeUntil(this.destroy$), takeUntil(this.signout.signoutSubject$))
      .subscribe(() => {
        if (!this.isVisible) {
          this.setIsVisible(true);
        }
      });

    fromEvent(window, 'blur')
      .pipe(takeUntil(this.destroy$), takeUntil(this.signout.signoutSubject$))
      .subscribe(() => {
        if (this.isVisible) {
          this.setIsVisible(false);
        }
      });

    fromEvent(window, 'freeze')
      .pipe(takeUntil(this.destroy$), takeUntil(this.signout.signoutSubject$))
      .subscribe(() => {
        if (this.isVisible) {
          this.setIsVisible(false);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private resumeIfPaused(): void {
    let timestamp = new Date().getTime();
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _this = this;
    setInterval(checkResume, 1000);

    function checkResume() {
      const current = new Date().getTime();
      _this.asleepMinutes = Math.round((current - timestamp) / 1000 / 60);

      if (current - timestamp > 60 * 60 * 1000) {  // Reload if asleep more than one hour
        window.location.reload();
      } else if (current - timestamp > 3 * 1000) {  // Trigger resume event if alive for more than 3 seconds
        const resumeEvent = new Event('resume');
        window.dispatchEvent(resumeEvent);
      }
      timestamp = current;
    }
  }

  private changesIfOnline(): void {
    if (!this.isConnected) {
      this.setIsConnected(true);
    }
  }

  private changesIfOffline(): void {
    if (this.isConnected) {
      this.setIsConnected(false);
    }
  }

  private setIsConnected(connected: boolean): void {
    this.isConnected = connected;
    this.updateIsActiveSubject();
  }

  private setIsVisible(visible: boolean): void {
    this.isVisible = visible;
    this.updateIsActiveSubject();
  }

  private updateIsActiveSubject(): void {
    // Only update subject if value changes
    if (this.isActiveSubject$.getValue() !== (this.isConnected && this.isVisible)) {
      this.isActiveSubject$.next(this.isConnected && this.isVisible);
    }
  }
}
