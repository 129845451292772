export interface UserSettings {
  uid: string;
  clientID: string;
  name: string;
  email: string;
  permissions: UserPermission[];
  locationIDs: string[];
  parentIDs: string[];
  betaUser: boolean;
  firstUse: string;
  uses: number;
  latestUse: string;
  latestVersion: string;
  prefs?: UserPreferences;
}

export interface UserPermission {
  id: string,
  state: number,
}

export interface PendingUser extends
  Omit<UserSettings, 'uid' | 'name' | 'firstUse' | 'uses' | 'latestUse' | 'latestVersion' | 'betaUser' | 'prefs'> {
  id: string;
  valid?: boolean;
  invitationSentDate?: Date;
}

export interface UserSetup extends PendingUser {
  pending: boolean;
  isEditable: boolean;
  formattedInvitationSentDate: string;
}

export interface UserPreferences {
  authPersistence: string;
  comparisonDayCustomOffset: number;
  comparisonDayID: string;
  comparisonShowEvent: boolean;
  costType: string;
  demoPOS: string;
  groupByCategory: boolean;
  heatmapFilterAnimateBy: string;
  heatmapFilterDaysOfWeek: string;
  heatmapFilterGroupBy: string;
  heatmapFilterLocations: string;
  heatmapFilterMetric: string;
  heatmapFilterShowThru: string;
  heatmapFilterShowThruCustomDate: string;
  heatmapFilterTimeframe: string;
  heatmapFilterTimeframeCustomTimeframe: number;
  heatmapFilterTimeframeCustomUnits: string;
  heatmapFilterTimeOfDay: string;
  heatmapFilterTimeOfDayEnd: number;
  heatmapFilterTimeOfDayStart: number;
  hiddenChannels: string;
  includeInactiveCategories: boolean;
  includeInactiveItems: boolean;
  lastError: string;
  locationID: string;
  promptForGoogle: boolean;
  showDemoMessage: boolean;
  showHistoryStatus: string;
  showMigrationAlert: boolean;
  showRefreshMessage: boolean;
  showTrendLine: boolean;
  stackTrends: boolean;
  stack100: boolean;
  trendsFilterAggregation: string;
  trendsFilterAggregationMTD: number;
  trendsFilterAggregationQTD: number;
  trendsFilterAggregationYTD: number;
  trendsFilterComparison: string;
  trendsFilterDayOfWeek: number;
  trendsFilterEvent: string;
  trendsFilterGroupBy: string;
  trendsFilterLocations: string;
  trendsFilterMetric: string;
  trendsFilterQuantity: string;
  trendsFilterShowThru: string;
  trendsFilterShowThruCustomDate: string;
  trendsFilterTimeOfDay: string;
  trendsFilterTimeOfDayEnd: number;
  trendsFilterTimeOfDayStart: number;
  zoomFactor: number;
}

// 0 = Full, 1 = View, 2 = None
export const permissionList = [
  { id: 'insights', name: 'Customize insights', states: [0, 1, 2]},
  { id: 'settings', name: 'Manage locations / connections', states: [0, 1, 2] },
  { id: 'catalog', name: 'Manage categories / products', states: [0, 1, 2] },
  { id: 'profit', name: 'Manage profitability analysis', states: [0, 1, 2] },
  { id: 'billing', name: 'Manage billing', states: [0, 1, 2], defaultState: 2 },
  { id: 'users', name: 'Manage users', states: [0, 1, 2], defaultState: 2 },
  { id: 'download', name: 'Download data', states: [0, 2] , stateNames: ['Allowed', '', 'None']},
  { id: 'locations', name: 'Locations', states: [0, 1] , stateNames: ['All', 'Selected', '']},
];

// Custom claims are user settings accessible to Firestore Rules
export interface CustomClaims {
  clientID: string;
}
